import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

class Submitted extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Form Submitted" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <h2>Form Submitted</h2>
            <p>
              Thank you for your message; we will get back to you as soon as we are able.
            </p>
            <ul className="actions">
              <li>
                <Link to="/" className="button">
                  Return Home
                </Link>
              </li>
            </ul>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Submitted
